import React, { Component } from "react";
import {
  Box,
  Button,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import sectionimage from "../image/3-section image.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export class DigitalMarkting extends Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={7}
          style={{ paddingTop: "80px", paddingBottom: "70px" }}
        >
          <Grid item>
            <CardMedia
              sx={{ height: "auto" }}
              src={sectionimage}
              component="img"
              alt="banner"
            />
            {/* <img
              src={sectionimage}
              style={{ height: "auto", width: "auto"}}
            ></img> */}
          </Grid>
          <Grid item xs={10} md="6">
            <Typography
              variant="h6"
              style={{
                fontWeight: "700",
                fontSize: "20px",
              }}
            >
              ABOUT US
            </Typography>
            <Typography
              variant="h6"
              marginTop="6px"
              style={{
                fontWeight: "600",
                fontSize: "25px",
              }}
            >
              <span
                style={{
                  fontWeight: "900",
                  fontSize: "25px",
                }}
              >
                WE ARE HERE TO PROVIDE YOU THE BEST CUSTOMIZED SOLUTION
              </span>
            </Typography>
            <Typography
              variant="body1"
              marginTop="30px"
              align="justify"
              style={{ fontSize: "18px" }}
            >
              Our primary objectives are to deliver high-quality work, satisfy
              customers, and ensure that everything works as intended. Online
              design & development, mobile application development, and
              customized software are the services we offer. We are here to meet
              the needs of the customer with the most cutting-edge data,
              technology, and design. The best products are what we aim to
              provide. Our team has received extensive training and has in-depth
              knowledge of the creation of the technologies. If you encounter
              any difficulties, our team will assist you. Our support staff will
              respond within a minute, and we also keep up with thorough
              development documentation. <br />
              <br />
              In order to speed up your process while increasing the
              productivity of your resources and your control over the
              processes, we are here to supply you with a Tailored Solution of
              your current working methodology that is of the highest quality
              and competence.
            </Typography>

            {/* <Stack direction="column" spacing={3} paddingTop="30px">
              <Stack direction="row" spacing={1.5}>
                <CheckCircleIcon sx={{ color: "#0047ab" }} />
                <Typography>
                  We provide wide array of Web Design and Development.
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1.5}>
                <CheckCircleIcon sx={{ color: "#0047ab" }} />
                <Typography>
                  Our websites are clean, clear and customized to your needs!
                </Typography>
              </Stack>
            </Stack> */}
          </Grid>
        </Grid>
        {/* <Grid container direction="row">
          <Grid item marginLeft="200px">
            <img
              src={sectionimage}
              style={{ height: "auto", width: "auto", maxHeight: "89.9vh" }}
            ></img>
          </Grid>
          <Grid item xs={12} sm>
            <Grid container="column">
              <Grid item marginTop="40px">
                //<Stack direction='row'> <h1>About</h1><h2>Smart</h2><h3>Business</h3> </Stack>
                <Typography variant="h6">
                  About
                  <span
                    style={{
                      color: "#ffba00",
                      fontWeight: "500px",
                      marginLeft: "5px",
                    }}
                  >
                    Smart
                  </span>
                  <span style={{ marginLeft: "5px" }}>Business</span>
                </Typography>

                <Typography variant="h6">
                  Best
                  <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                    Digital Markting Agency
                  </span>{" "}
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Since 2xxx over the Internet
                  </span>{" "}
                </Typography>

                <Typography variant="body2">
                  {" "}
                  Lorem ipsum is simply dummy text of the priting and
                  typesetting industry.Lorem ipsum has been the industry's
                  standard dummy text ever since the 1500 when an unknown
                  printer took a Gallery of type and scrambled it to make a type
                  specciman book{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid item marginLeft="10px">
              <Stack direction="row">
                {" "}
                <CheckCircleIcon sx={{ color: "#0047ab" }} /> &nbsp;it has
                survived not only five minutes{" "}
              </Stack>{" "}
              <br />
              <Stack direction="row">
                {" "}
                <CheckCircleIcon sx={{ color: "#0047ab" }} /> &nbsp;it has
                survived not only five minutes{" "}
              </Stack>
              <br />
              <Stack direction="row">
                {" "}
                <CheckCircleIcon sx={{ color: "#0047ab" }} /> &nbsp;it has
                survived not only five minutes{" "}
              </Stack>{" "}
              <br />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#ffba00",
                  color: "#fffffe",
                  ":hover": {
                    color: "#ffba01",
                    opacity: 1,
                  },
                }}
              >
                Discover More
              </Button>
            </Grid>
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

export default DigitalMarkting;
