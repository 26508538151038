import React, { Component } from "react";

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backToTop: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 50) {
      this.setState({ backToTop: true });
    } else {
      this.setState({ backToTop: false });
    }
  };

  scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    const { backToTop } = this.state;
    return (
      <div className="App">
        {backToTop && (
          <button
            style={{
              position: "fixed",
              bottom: "50px",
              right: "50px",
              height: "50px",
              width: "50px",
              fontSize: "50px",
            }}
            onClick={this.scrollUp}
          >
            ^
          </button>
        )}
      </div>
    );
  }
}

export default ScrollToTop;
