import axios, * as others from 'axios';
export const localBackEndURL = "http://127.0.0.1/Development/RaviTechWorld/ravitechworld-react-2.0-backend/"

export const domain = "https://ravitechworld.com"

export const frontEndURL = domain// + "/LiveTesting/RaviTechWorld/"

export const onlineBackEndURL = frontEndURL + "/BackEndApi/"

export const apiURL = onlineBackEndURL //localBackEndURL

// const axios = require("axios");
const axiosInstance = axios.create({
    baseURL: apiURL
});

export function fetchProduct(data) {
    return axiosInstance.post("productdetail.php", data)
}

export function fetchcontactusdetail(data) {
    return axiosInstance.post("contactusdetail.php", data)
}