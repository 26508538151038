export const setActiveMenu = (ActiveMenu) => {
    return {
        type: 'SET_ActiveMenu',
        ActiveMenu
    }
}

/* export const addPost = (id, title) => {
    return {
        type: 'ADD_POST',
        id,
        title
    }
} */