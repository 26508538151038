import React from 'react';
import { styled } from '@mui/system';
import { Box, Grid } from '@mui/material';

const BoxHeading = styled(Box)(({ theme }) => ({
  backgroundColor: 'lightgray',
  textAlign: 'center',
}));

const MyComponent = () => {

  const gridStyle = { backgroundColor: 'lightblue' }
  const firstItem = { backgroundColor: 'yellow' }
  const secondItem = { backgroundColor: 'white' }
  const thirdItem = { backgroundColor: 'orange' }

  return (
    <div>
      <BoxHeading>
        direction="row"
        justifyContent='flex-start'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='flex-start'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>1</Grid>
        <Grid item style={secondItem}>2</Grid>
        <Grid item style={thirdItem}>3</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='center'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='center'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>1</Grid>
        <Grid item style={secondItem}>2</Grid>
        <Grid item style={thirdItem}>3</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='flex-end'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='flex-end'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>4</Grid>
        <Grid item style={secondItem}>5</Grid>
        <Grid item style={thirdItem}>6</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='BoxHeading'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='BoxHeading'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>7</Grid>
        <Grid item style={secondItem}>8</Grid>
        <Grid item style={thirdItem}>9</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='space-between'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='space-between'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>1</Grid>
        <Grid item style={secondItem}>2</Grid>
        <Grid item style={thirdItem}>3</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='space-around'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='space-around'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>4</Grid>
        <Grid item style={secondItem}>5</Grid>
        <Grid item style={thirdItem}>6</Grid>
      </Grid>
      <BoxHeading>
        direction="row"
        justifyContent='space-evenly'
      </BoxHeading>
      <Grid
        style={gridStyle}
        container
        direction="row"
        justifyContent='space-evenly'
        alignItems="BoxHeading"
      >
        <Grid item style={firstItem}>7</Grid>
        <Grid item style={secondItem}>8</Grid>
        <Grid item style={thirdItem}>9</Grid>
      </Grid>
    </div>
  );
};

export default MyComponent;
