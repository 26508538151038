const initState = {
  navInfo: {
    activeMenu: '',
    subMenu:[]
  },
  userInfo:{
    activeUser:'',
  }
}

const RootReducers = (state = initState, action) => {
  if (action.type === 'SET_ActiveMenu') {
    return {
      ...state,
      navInfo: { ...state.navInfo, activeMenu: action.ActiveMenu }
    }
  } /*else if (action.type === 'ADD_POST') {
    let newPosts = state.posts
    newPosts.push({ id: action.id, title: action.title })
    return {
      ...state,
      posts: newPosts
    }
  } */
  return state;
}

export default RootReducers