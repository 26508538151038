import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { Component } from "react";
import ServiceImg from "./image/ServiceImg.png";
import web from "./image/web.png";
import app from "./image/app.png";
import Software from "./image/software.png";
import sms from "./image/sms.png";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "./mapRedux/mapReduxProps";
import { GridOff } from "@mui/icons-material";
import MyComponent from "./MyComponent";
import { styled } from "@mui/system";
import Footer from "./home/Footer";

const StyledGrid_ContextHeading = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    textAlign: "center",
    width: "100%",
  },
  [theme.breakpoints.up("sm")]: {
    textAlign: "justify",
  },
}));

class Service extends Component {
  //#region Component
  componentDidMount() {
    this.props.setActiveMenu("Service");
    document.title = "Service | Ravi Tech World";
  }
  //#endregion Component
  render() {
    const styleServiceIcon = {
      height: "100px",
      width: "auto",
      maxWidth: "100px",
    };
    const styleContextHeading = {
      fontWeight: "600",
      fontSize: "25px",
      color: "#3d3d3d" /* maxWidth: '400px', */,
    };
    const styleContextBody = {
      fontWeight: "300",
      fontSize: "15px",
      color: "#3d3d3d",
      textAlign: "justify" /* maxWidth: '400px', */,
    };

    return (
      <div>
        <div>
          <Card
            elevation={0}
            sx={{ backgroundColor: "#ecebeb", marginTop: "-67px" }}
          >
            <CardMedia
              sx={{ height: "auto", minHeight: "250px" }}
              src={ServiceImg}
              component="img"
              alt="ServiceBanner"
            ></CardMedia>
            <CardContent>
              <Grid container direction={"row"} justifyContent={"center"}>
                <Grid item md={"8"}>
                  <Typography
                    marginTop="30px"
                    mb={"30px"}
                    align="center"
                    style={{
                      fontWeight: "300",
                      fontSize: "18px",
                      color: "#504f4f",
                      textAlign: "justify",
                    }}
                  >
                    Ravi Tech World is the Customize Software, Web and Mobile
                    App design and development company. We offers a wide range
                    of services for websites, software, applications, various
                    kinds of automation of software and web, domains, and
                    hosting. We are ready to provide the best solution if you
                    need new software development, a professional website, or
                    eCommerce web. We have successfully created a variety of
                    commercial software for invoicing, stock, data processing,
                    user management and other various customised solutions,
                    online portals for users to manage their work, and
                    applications for the Android and iOS platforms. We use
                    React, Node.js, Android, Flutter, IOS, PHP, .NET, MySQL,
                    MSSQL, Ajax, JQuery, technologies to create stunning
                    applications and websites.
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </div>
        <div>
          <Grid container direction={"column"} mt="16px" alignItems={"center"}>
            <Grid item>
              <Grid
                container
                direction={"row"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{ padding: "16px" }}
              >
                <Grid
                  item
                  md={6}
                  // lg={4}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <img src={web} style={styleServiceIcon} />
                  </Grid>
                  <Grid
                    item
                    sm={9}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <StyledGrid_ContextHeading>
                      <Typography style={styleContextHeading}>
                        Web Design and Development
                      </Typography>
                    </StyledGrid_ContextHeading>
                    <Grid item>
                      <Typography
                        marginTop="5px"
                        mb={"30px"}
                        style={styleContextBody}
                      >
                        We design, develop, and maintain the websites. It
                        comprises elements like web publishing, database
                        administration, web design, and web programming. We are
                        building websites using all necessary frameworks,
                        including React, NodeJs, Core PHP, and Wordpress etc.
                        <br />
                        We are providing: Customised web platform & web service
                        development, Static web design, Dynamic web design,
                        E-Commerce Website, etc.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  // lg={4}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <img src={app} style={styleServiceIcon} />
                  </Grid>
                  <Grid
                    item
                    sm={9}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <StyledGrid_ContextHeading>
                      <Typography style={styleContextHeading}>
                        Mobile Apps Development
                      </Typography>
                    </StyledGrid_ContextHeading>
                    <Grid item>
                      <Typography
                        marginTop="5px"
                        mb={"30px"}
                        style={styleContextBody}
                      >
                        We are working on mobile applications for both iOS and
                        Android in native and cross platform like flutter. We
                        have created applications for customised need, product
                        administration, billing software, and report generation,
                        etc. According to user needs, a wide range of services
                        are provided in app creation.
                        <br />
                        We are providing: Android Apps, IOS Apps, Flutter Apps
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                direction={"row"}
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                style={{ padding: "16px" }}
              >
                <Grid
                  item
                  md={6}
                  // lg={4}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <img src={web} style={styleServiceIcon} />
                  </Grid>
                  <Grid
                    item
                    sm={9}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <StyledGrid_ContextHeading>
                      <Typography style={styleContextHeading}>
                        Software Development
                      </Typography>
                    </StyledGrid_ContextHeading>
                    <Grid item>
                      <Typography
                        marginTop="5px"
                        mb={"30px"}
                        style={styleContextBody}
                      >
                        With the help of our software development skills, we are
                        able to meet all of our customers' needs for automation.
                        Customers can enter and manage all of their data with
                        relative ease using our user-friendly designs and
                        language-neutral software solutions. Anyone who have
                        either lower computer knowledge or new to operate
                        computer can handle the software and get required
                        solution with very less efforts.
                        <br />
                        We are providing: ERP for Manufacturing Plants, APMC
                        Management, Billing Software, Hospital Management, CA
                        Office Management Software, Weight Bridge Software, etc.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={6}
                  // lg={4}
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  spacing={2}
                >
                  <Grid item xs={"auto"}>
                    <img src={app} style={styleServiceIcon} />
                  </Grid>
                  <Grid
                    item
                    sm={9}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                  >
                    <StyledGrid_ContextHeading>
                      <Typography style={styleContextHeading}>
                        Bulk messages
                      </Typography>
                    </StyledGrid_ContextHeading>
                    <Grid item>
                      <Typography
                        marginTop="5px"
                        mb={"30px"}
                        style={styleContextBody}
                      >
                        We are providing bulk messages facility to users who
                        need to share their information through the SMS platform
                        on mobile. The validity of the SMS is lifetime, which
                        helps you to save money and use it anytime. The SMS
                        service is a Transactional SMS service that delivers the
                        message quickly at any time. Also can schedule the
                        Messages for a particular date, time, and mobile no.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
  /* //#region Component
    componentDidMount() {
        this.props.setActiveMenu("Service");
        document.title = "Service | Ravi Tech World"
    }
    //#endregion Component
    render() {

        const styleServiceIcon = { height: "100px", width: "auto", maxWidth: "100px" }

        return (
            <div>
                <div>
                    <Card elevation={0} sx={{ backgroundColor: "#ecebeb", marginTop: "-67px" }}>
                        <CardMedia
                            sx={{ height: "auto" }}
                            src={ServiceImg}
                            component="img"
                            alt="ServiceBanner"
                        ></CardMedia>
                        <CardContent>
                            <Grid container direction={"row"} justifyContent={"center"}>
                                <Grid item md={"8"}>
                                    <Typography
                                        marginTop="30px"
                                        mb={"30px"}
                                        align="center"
                                        style={{
                                            fontWeight: "300",
                                            fontSize: "18px",
                                            color: "#504f4f",
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Ravi Tech World is the top Software, Web and Mobile App design and development company. We offers a wide range of services for websites, software, applications, various kinds of automation of software and web, domains, and hosting. We are ready to provide the best solution if you need new software development, a professional website, or eCommerce web. We have successfully created a variety of commercial software for invoicing, stock, data processing, user management and other various customised solutions, online portals for users to manage their work, and applications for the Android and iOS platforms. We use React, Node.js, Android, Flutter, IOS, PHP, .NET, MySQL, MSSQL, Ajax, JQuery, technologies to create stunning applications and websites.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Grid container direction={"row"} justifyContent="center" alignItems="flex-start" spacing={2} style={{ padding: '16px' }} >
                        <Grid item md="4" container direction={"column"} justifyContent="center" alignItems="center">
                            <Grid item>
                                <img src={web} style={styleServiceIcon} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "25px",
                                        color: "#3d3d3d",
                                    }}
                                >
                                    Web Design and Development
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    marginTop="5px"
                                    style={{
                                        fontWeight: "300",
                                        fontSize: "15px",
                                        color: "#3d3d3d",
                                        textAlign: 'justify',
                                    }}
                                >
                                    We design, develop, and maintain the websites. It comprises elements like web publishing, database administration, web design, and web programming. We are building websites using all necessary frameworks, including React, NodeJs, Core PHP, and Wordpress etc.<br />
                                    We are providing: Customised web platform & web service development, Static web design, Dynamic web design, E-Commerce Website, etc.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md="4" container direction={"column"} justifyContent="center" alignItems="center">
                            <Grid item>
                                <img src={app} style={styleServiceIcon} ></img>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "25px",
                                        color: "#3d3d3d",
                                    }}
                                >
                                    Mobile Apps Development
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    marginTop="5px"
                                    style={{
                                        fontWeight: "300",
                                        fontSize: "15px",
                                        color: "#3d3d3d",
                                        textAlign: 'justify',
                                    }}
                                >
                                    We are working on mobile applications for both iOS and Android in native and cross platform like flutter. We have created applications for customised need, product administration, billing software, and report generation, etc. According to user needs, a wide range of services are provided in app creation.<br />
                                    We are providing: Android Apps, IOS Apps, Flutter Apps
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction={"row"} justifyContent="center" alignItems="flex-start" spacing={2} style={{ padding: '16px' }}>
                        <Grid item md="4" container direction={"column"} justifyContent="center" alignItems="center">
                            <Grid item>
                                <img src={Software} style={styleServiceIcon} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "25px",
                                        color: "#3d3d3d",
                                    }}
                                >
                                    Software Development
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    marginTop="5px"
                                    mb={"30px"}
                                    style={{
                                        fontWeight: "300",
                                        fontSize: "15px",
                                        color: "#3d3d3d",
                                        textAlign: 'justify',
                                    }}
                                >
                                    With the help of our software development skills, we are able to meet all of our customers' needs for automation. Customers can enter and manage all of their data with relative ease using our user-friendly designs and language-neutral software solutions. Anyone who have either lower computer knowledge or new to operate computer can handle the software and get required solution with very less efforts.<br />
                                    We are providing: ERP for Manufacturing Plants, APMC Management, Billing Software, Hospital Management, CA Office Management Software, Weight Bridge Software, etc.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item md="4" container direction={"column"} justifyContent="center" alignItems="center">
                            <Grid item>
                                <img src={sms} style={styleServiceIcon} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={{
                                        fontWeight: "600",
                                        fontSize: "25px",
                                        color: "#3d3d3d",
                                    }}
                                >
                                    Bulk messages
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    marginTop="5px"
                                    mb={"30px"}
                                    style={{
                                        fontWeight: "300",
                                        fontSize: "15px",
                                        color: "#3d3d3d",
                                        textAlign: 'justify'
                                    }}
                                >
                                    We are providing bulk messages facility to users who need to share their information through the SMS platform on mobile. The validity of the SMS is lifetime, which helps you to save money and use it anytime. The SMS service is a Transactional SMS service that delivers the message quickly at any time. Also can schedule the Messages for a particular date, time, and mobile no.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {/* <MyComponent /> * /}
            </div>
        );
    } */
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(Service);

// export default Service;
