import { Grid, Stack } from "@mui/material";
import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import First from "../image/expertise/1 react.png";
import PHP from "../image/expertise/2 PHP.png";
import Android from "../image/expertise/3 Android.png";
import Flutter from "../image/expertise/4 Flutter.png";
import SQL from "../image/expertise/5 SQL.png";
import VBnet from "../image/expertise/6 VB.net.png";
import AngularJS from "../image/expertise/7 AngularJS.png";

export default class Experties extends Component {
  render() {
    return (
      <div>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          spacing={8}
          sx={{ backgroundColor: "#F0F0F0" }}
          style={{ paddingTop: "40px", paddingBottom: "40px" }}
        >
          <Grid
            item
            xs={8}
            sm={7}
            md={4}
            lg={3}
            alignItems="center"
            justifyContent="center"
          >
            <Grid container direction="column" alignContent="center">
              <Typography
                variant="h6"
                component="div"
                color="#000"
                style={{
                  paddingTop: "50px",
                  fontWeight: "bold",
                }}
              >
                OUR{" "}
                <span style={{ color: "#ED3234", fontWeight: "800" }}>
                  EXPERTISE
                </span>
              </Typography>
              <Typography
                variant="h4"
                component="div"
                color="#000"
                style={{
                  fontWeight: "bold",
                  fontWeight: "800",
                }}
              >
                WE OFFER
              </Typography>
              <Divider
                sx={{
                  height: "3px",
                  width: "110px",
                  backgroundColor: "blue",
                  display: "block",
                }}
                style={{ marginTop: "7px" }}
              />
              <Typography
                variant="body2"
                style={{
                  fontSize: "13px",
                  color: "#7D7D7D",
                  marginTop: "10px",
                }}
              >
                Cutting-edge solutions that are <br />
                really simple to use.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10} md={7} lg={8}>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <img src={First} style={{ width: "auto", height: 180 }} />
              </Grid>
              <Grid item>
                <img src={PHP} style={{ width: "auto", height: 180 }} />
              </Grid>
              <Grid item>
                <img src={Android} style={{ width: "auto", height: 180 }} />
              </Grid>
              <Grid item>
                <img src={Flutter} style={{ width: "auto", height: 180 }} />
              </Grid>
              {/* </Grid> */}
              {/* <Grid container direction="row" justifyContent="center"> */}
              <Grid item>
                <img src={SQL} style={{ width: "auto", height: 180 }} />
              </Grid>
              <Grid item>
                <img src={VBnet} style={{ width: "auto", height: 180 }} />
              </Grid>
              <Grid item>
                <img src={AngularJS} style={{ width: "auto", height: 180 }} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={20}
            sm={12}
            md={12}
            lg={5}
            style={{ textAlign: "center" }}
          >
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              color="#000"
              style={{
                paddingTop: "40px",
                paddingLeft: "14px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              OUR{" "}
              <span style={{ color: "#ED3234", fontWeight: "800" }}>
                EXPERTISE
              </span>
            </Typography>
            <Typography
              variant="h4"
              component="div"
              color="#000"
              style={{
                textAlign: "center",
                paddingLeft: "35px",
                fontWeight: "bold",
                marginTop: "-6px",
                fontWeight: "800",
              }}
            >
              WE OFFER
            </Typography>
            <Divider
              sx={{
                width: "110px",
                backgroundColor: "blue",
                display: "block",
                padding: "1px",
              }}
              variant="inset"
              style={{ marginLeft: "240px", marginTop: "6px" }}
            />
            <Typography
              variant="caption"
              gutterBottom
              component="div"
              style={{
                textAlign: "center",
                color: "#7D7D7D",
                paddingLeft: "55px",
                marginTop: "12px",
              }}
            >
              Lorem lpsum is simply dummy text of <br />
              the printing and typesetting industry.
            </Typography>
          </Grid>
          <Grid item xs={20} sm={12} md={12} lg={6}>
            <Grid container direction="row" spacing={2} justifyContent="center">
              <Grid item>
                <img src={First} style={{ width: 200, height: 180 }} />
              </Grid>
              <Grid item>
                <img src={PHP} style={{ width: 200, height: 180 }} />
              </Grid>
              <Grid item>
                <img src={Android} style={{ width: 200, height: 180 }} />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} justifyContent="center">
              <Grid item>
                <img src={AngularJS} style={{ width: 200, height: 180 }} />
              </Grid>
              <Grid item>
                <img src={SQL} style={{ width: 200, height: 180 }} />
              </Grid>
              <Grid item>
                <img src={VBnet} style={{ width: 200, height: 180 }} />
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
      </div>
    );
  }
}
