import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import "./Menu.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "./image/Header.png";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Home, Contacts, DesignServicesRounded } from "@mui/icons-material";
import {
  Info,
  Facebook,
  Twitter,
  Instagram,
  Language,
  YouTube,
  LinkedIn,
  LibraryBooks,
  AccountCircle,
  Assignment,
  LockOpen,
  FormatListBulleted,
  AssignmentInd,
} from "@mui/icons-material";
import LanguageRounded from "@mui/icons-material/LanguageRounded";
import AlertResponsiveDialog from "./CustomTool/AlertResponsiveDialog";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "./mapRedux/mapReduxProps";
import { click } from "@testing-library/user-event/dist/click";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));


const LinkTo = ({
  ItemName,
  LinkSlug,
  activeMenu,
  handleLinkToOnClick,
  activeStyle,
  className = "LinkToTransparent"
}) => {
  return (
    <Link
      to={LinkSlug}
      className={className}
      style={activeMenu === LinkSlug ? activeStyle : {}}
      onClick={() => handleLinkToOnClick(LinkSlug)}
    >
      {ItemName}
    </Link>
  );
};

const MenuListItemSvgIcon = ({
  ItemName,
  LinkSlug,
  ItemIcon,
  activeMenu,
  activeStyle,
  handleDrawerClose,
  handleLinkToOnClick,
}) => {
  return (
    <Link
      to={LinkSlug}
      className="menulink"
      style={activeMenu === LinkSlug ? activeStyle : {}}
      onClick={() => handleLinkToOnClick(LinkSlug)}
    >
      <ListItem
        button
        key={ItemName}
        onClick={handleDrawerClose}
        style={{ color: "#81BB39", fontWeight: "600" }}
      >
        <ListItemIcon>{<ItemIcon className="svgicon" />}</ListItemIcon>
        <ListItemText primary={ItemName} />
      </ListItem>
    </Link>
  );
};

const MenuListItemIcon = ({
  ItemName,
  LinkSlug,
  ItemIcon,
  activeMenu,
  handleDrawerClose,
  activeStyle,
  handleLinkToOnClick,
}) => {
  return (
    <Link
      to={LinkSlug}
      className="menulink"
      style={activeMenu === LinkSlug ? activeStyle : { fontWeight: "600" }}
      onClick={() => handleLinkToOnClick(LinkSlug)}
    >
      <ListItem button key={ItemName} onClick={handleDrawerClose}>
        <ListItemIcon>{<ItemIcon className="muicon" />}</ListItemIcon>
        <ListItemText primary={ItemName} disableTypography={true} />
      </ListItem>
    </Link>
  );
};

const Menu = (props) => {
  const [loginData, setloginData] = useState({
    logInID: "",
    logInUserType: "",
    logInLinkText: "લોગ ઈન",
    logInDashboardLink: "",
  });

  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const bgColor = "#fafafa";

  // const activeStyle = { color: '#77AC35', 'fontWeight': '600' }
  const activeStyle = { color: "red", fontWeight: "600" };

  const clearLocalStorage = () => {
    localStorage.removeItem("logInID");
    localStorage.removeItem("logInUserType");
  };

  const getIsAdmin = () => {
    return localStorage.getItem("logInUserType") &&
      localStorage.getItem("logInUserType") === "A"
      ? true
      : false;
  };

  const handleUserLoginID = (logInID) => {
    const userType = "U";
    const logInUserType = (logInID && logInID.length) > 0 ? userType : "";
    const logInDashboardLink =
      (logInID && logInID.length) > 0 ? "UserDashboard" : "";
    setloginData(
      {
        ...loginData,
        logInID: loginData.logInID,
        logInUserType: loginData.logInUserType,
        logInDashboardLink: loginData.logInDashboardLink,
      },
      () => {
        let logInLinkText = "Log In";
        clearLocalStorage();
        if (
          loginData.logInID &&
          loginData.logInID.length > 0 &&
          Number(loginData.logInID) > 99999
        ) {
          localStorage.setItem("logInID", loginData.logInID);
          localStorage.setItem("logInUserType", userType);
          logInLinkText = "Log Out";
        }
        setloginData({
          ...loginData,
          logInID: loginData.logInID,
          logInUserType: loginData.logInUserType,
          logInDashboardLink: loginData.logInDashboardLink,
        });
      }
    );
  };
  const handleAdminLoginID = (logInID) => {
    const userType = "A";
    const logInUserType = (logInID && logInID.length) > 0 ? userType : "";
    const logInDashboardLink =
      (logInID && logInID.length) > 0 ? "AdminDashboard" : "";
    setloginData(
      {
        ...loginData,
        logInID: loginData.logInID,
        logInUserType: loginData.logInUserType,
        logInDashboardLink: loginData.logInDashboardLink,
      },
      () => {
        let logInLinkText = "Log In";

        clearLocalStorage();
        if (loginData.logInID && loginData.logInID.length > 0) {
          localStorage.setItem("logInID", loginData.logInID);
          localStorage.setItem("logInUserType", userType);
          logInLinkText = "Log Out";
        }
        setloginData({
          ...loginData,
          logInID: loginData.logInID,
          logInUserType: loginData.logInUserType,
          logInDashboardLink: loginData.logInDashboardLink,
        });
      }
    );
  };

  /* useEffect(() => {
        if (localStorage.getItem('logInID') && localStorage.getItem('logInUserType')) {
            if (localStorage.getItem('logInID').length > 0 && localStorage.getItem('logInUserType') === 'U'
            ) {
                handleUserLoginID(localStorage.getItem('logInID'))
            } else if (localStorage.getItem('logInID').length > 0 && localStorage.getItem('logInUserType') === 'A') {
                handleAdminLoginID(localStorage.getItem('logInID'))
            } else {
                clearLocalStorage()
            }
        } else {
            clearLocalStorage()
        }
    }); */

  // const activeMenu = props && props.navInfo && props.navInfo.activeMenu ? props.navInfo.activeMenu : ''

  //---- change nav color when scrolling ----
  const [color, setColor] = useState(false);
  const closeDrawer = () => { setOpen(false) }
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  const isTransparentMenuTextIcon = 
    props.navInfo.activeMenu !== undefined && 
    props.navInfo.activeMenu !== "products" && 
    props.navInfo.activeMenu !== "other" && 
    window.scrollY < 90
  const linkToClassName = isTransparentMenuTextIcon ? 'LinkToTransparent' : 'LinkTo'
  const appBarToolBarIconButtonColor = isTransparentMenuTextIcon ? 'white' : 'blue'
  const appBarBoxShadowSX = isTransparentMenuTextIcon ? { boxShadow: 'none' } : {}
  window.addEventListener("scroll", changeColor);

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        <AppBar position="fixed" color="transparent"
          sx={appBarBoxShadowSX}
        >
          <Toolbar className={color ? "appBarSolid" : "appBarTransprent"}>
            <IconButton
              // color="white"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }), color: appBarToolBarIconButtonColor }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => props.setActiveMenu("Home")}
                  >
                    {/* ખેડૂત માર્ગદર્શક */}
                    <img
                      src={Header}
                      alt="Logo"
                      style={{
                        width: "150px",
                        height: "auto",
                        marginTop: "7px",
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item md>
                  <Box
                    display={{ xs: "none", md: "block" }}
                    style={{ textAlign: "right" }}
                  >
                    <List >
                      <LinkTo
                        className={linkToClassName}
                        ItemName={"Home"}
                        LinkSlug={""}
                        activeMenu={props.navInfo.activeMenu}
                        handleLinkToOnClick={props.setActiveMenu}
                        activeStyle={activeStyle}
                      />
                      <LinkTo
                        className={linkToClassName}
                        ItemName={"About us"}
                        LinkSlug={"AboutUs"}
                        activeMenu={props.navInfo.activeMenu}
                        handleLinkToOnClick={props.setActiveMenu}
                        activeStyle={activeStyle}
                      />
                      <LinkTo
                        className={linkToClassName}
                        ItemName={"Services"}
                        LinkSlug={"Service"}
                        activeMenu={props.navInfo.activeMenu}
                        handleLinkToOnClick={props.setActiveMenu}
                        activeStyle={activeStyle}
                      />
                      <LinkTo
                        className={linkToClassName}
                        ItemName={"Contact us"}
                        LinkSlug={"ContactUs"}
                        activeMenu={props.navInfo.activeMenu}
                        handleLinkToOnClick={props.setActiveMenu}
                        activeStyle={activeStyle}
                      />
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>

            <MenuListItemIcon
              ItemName={"Home"}
              LinkSlug={""}
              ItemIcon={Home}
              activeMenu={props.navInfo.activeMenu}
              handleDrawerClose={closeDrawer}
              activeStyle={activeStyle}
              handleLinkToOnClick={props.setActiveMenu}
            />

            <MenuListItemIcon
              ItemName={"About Us"}
              LinkSlug={"AboutUs"}
              ItemIcon={LibraryBooks}
              activeMenu={props.navInfo.activeMenu}
              handleDrawerClose={closeDrawer}
              activeStyle={activeStyle}
              handleLinkToOnClick={props.setActiveMenu}
            />

            <MenuListItemIcon
              ItemName={"Services"}
              LinkSlug={"Service"}
              ItemIcon={DesignServicesRounded}
              activeMenu={props.navInfo.activeMenu}
              handleDrawerClose={closeDrawer}
              activeStyle={activeStyle}
              handleLinkToOnClick={props.setActiveMenu}
            />
            <MenuListItemIcon
              ItemName={"Contact us"}
              LinkSlug={"ContactUs"}
              ItemIcon={Contacts}
              activeMenu={props.navInfo.activeMenu}
              handleDrawerClose={closeDrawer}
              activeStyle={activeStyle}
              handleLinkToOnClick={props.setActiveMenu}
            />
          </List>
          <Divider />
          <List style={{ backgroundColor: "#F3FCF4" }}>
            <a
              href="https://facebook.com/"
              rel="noreferrer noopener"
              target="_blank"
              className="menulink"
            >
              <ListItem button key="Facebook">
                <ListItemIcon>{<Facebook className="muicon" />}</ListItemIcon>
                <ListItemText
                  primary="Facebook"
                  className="fontbolder"
                  disableTypography={true}
                  sx={{
                    ":hover": {
                      color: "#131fcef5color",
                      opacity: 1,
                    },
                  }}
                />
              </ListItem>
            </a>
            <Link to="" className="menulink">
              <ListItem button key="Twitter">
                <ListItemIcon>{<Twitter className="muicon" />}</ListItemIcon>
                <ListItemText
                  primary="Twitter"
                  className="fontbolder"
                  disableTypography={true}
                  sx={{
                    ":hover": {
                      color: "#131fcef5",
                      opacity: 1,
                    },
                  }}
                />
              </ListItem>
            </Link>
            <Link to="" className="menulink">
              <ListItem button key="Instagram">
                <ListItemIcon>{<Instagram className="muicon" />}</ListItemIcon>
                <ListItemText
                  primary="Instagram"
                  className="fontbolder"
                  disableTypography={true}
                  sx={{
                    ":hover": {
                      color: "#131fcef5",
                      opacity: 1,
                    },
                  }}
                />
              </ListItem>
            </Link>
            <Link to="" className="menulink">
              <ListItem button key="Linked In">
                <ListItemIcon>{<LinkedIn className="muicon" />}</ListItemIcon>
                <ListItemText
                  primary="Linked In"
                  className="fontbolder"
                  disableTypography={true}
                  sx={{
                    ":hover": {
                      color: "#131fcef5",
                      opacity: 1,
                    },
                  }}
                />
              </ListItem>
            </Link>
            <Link to="" className="menulink">
              <ListItem button key="Youtube">
                <ListItemIcon>{<YouTube className="muicon" />}</ListItemIcon>
                <ListItemText
                  primary="Youtube"
                  className="fontbolder"
                  disableTypography={true}
                  sx={{
                    ":hover": {
                      color: "#131fcef5",
                      opacity: 1,
                    },
                  }}
                />
              </ListItem>
            </Link>
          </List>
        </Drawer>
        <Main open={open} style={{ padding: "0px" }}>
          <DrawerHeader />
        </Main>
      </Box>
    </div>
  );
};

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(Menu);
