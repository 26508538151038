import {
  Grid,
  Button,
  Stack,
  Typography,
  Divider,
  Box,
  Icon,
} from "@mui/material";
import React, { Component } from "react";
import Logo from "../image/Logo.png";
import contact from "../image/contact Logo.png";
import web from "../image/web Logo.png";
import Email from "../image/Email Logo.png";
import address from "../image/address Logo.png";
import Rimage from "../image/R2.png";
import R3 from "../image/R3.png";
import facebooklogo from "../image/facebook.png";
import youtube from "../image/youtube.png";
import linkedin from "../image/linkedin.png";
import { AlignHorizontalCenter } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "../mapRedux/mapReduxProps";

const LinkTo = ({
  ItemName,
  LinkSlug,
  activeMenu,
  handleLinkToOnClick,
  activeStyle,
}) => {
  return (
    <Link
      to={"/" + LinkSlug}
      className="QuickLink"
      style={activeMenu === LinkSlug ? activeStyle : {}}
      onClick={() => handleLinkToOnClick(LinkSlug)}
    >
      {ItemName}
    </Link>
  );
};

const activeStyle = { color: "red", fontWeight: "600" };

class Footer extends Component {
  render() {
    return (
      <div>
        <Grid
          container
          sx={{ backgroundColor: "#09305c" }}
          direction="row"
          justifyContent="center"
          // spacing={4}
        >
          <Grid item>
            <Box
              marginTop="25px"
              sx={{
                width: 330,
                height: 350,
                backgroundColor: "#021c45",
                borderRadius: "25px",
                ":hover": {
                  backgroundColor: "#02132e",
                },
              }}
            >
              <img
                src={R3}
                style={{
                  height: "25%",
                  width: "auto",
                  paddingLeft: "20px",
                  paddingTop: "30px",
                }}
              ></img>
              <Typography
                variant="h6"
                style={{
                  color: "#fffffe",
                  fontWeight: "200",
                  fontSize: "15px",
                  paddingLeft: "20px",
                  paddingTop: "10px",
                  paddingRight: "20px",
                }}
              >
                Obtain a solution with a long lifespan that can be upgraded to a
                any scale or platform in the future.
              </Typography>
              <Typography
                style={{
                  color: "#fffffe",
                  fontSize: "23px",
                  fontWeight: "600",
                  marginLeft: "20px",
                  marginTop: "15px",
                }}
              >
                Follow us
              </Typography>
              <Grid container marginTop="10px">
                <Grid item>
                  <a
                    href="https://www.facebook.com/RavitechWorld"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={facebooklogo}
                      style={{
                        height: "auto",
                        width: "16%",
                        marginLeft: "20px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCVhrd5Y84JMxJFGzTA-jAYA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={youtube}
                      style={{
                        height: "auto",
                        width: "15%",
                        marginLeft: "20px",
                      }}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/ravitechworld/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={linkedin}
                      style={{
                        height: "auto",
                        width: "15%",
                        marginLeft: "20px",
                      }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Grid
              container
              justifyContent="flex-start"
              // alignItem="flex-start"
              spacing={5}
              marginTop={0}
              marginLeft={3}
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  // marginTop="30px"
                  // md={1.5}
                  // marginLeft="70px"
                >
                  <Typography
                    style={{
                      color: "#fffffe",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    QUICK LINKS
                  </Typography>
                  <LinkTo
                    ItemName={"Home"}
                    LinkSlug={""}
                    activeMenu={this.props.navInfo.activeMenu}
                    handleLinkToOnClick={this.props.setActiveMenu}
                    activeStyle={activeStyle}
                  />
                  <LinkTo
                    ItemName={"About us"}
                    LinkSlug={"AboutUs"}
                    activeMenu={this.props.navInfo.activeMenu}
                    handleLinkToOnClick={this.props.setActiveMenu}
                    activeStyle={activeStyle}
                  />
                  <LinkTo
                    ItemName={"Services"}
                    LinkSlug={"Service"}
                    activeMenu={this.props.navInfo.activeMenu}
                    handleLinkToOnClick={this.props.setActiveMenu}
                    activeStyle={activeStyle}
                  />
                  <LinkTo
                    ItemName={"Contact us"}
                    LinkSlug={"ContactUs"}
                    activeMenu={this.props.navInfo.activeMenu}
                    handleLinkToOnClick={this.props.setActiveMenu}
                    activeStyle={activeStyle}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Typography
                    style={{
                      color: "#fffffe",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    CONTACT US
                  </Typography>
                  <Stack direction="row" marginTop="10px" spacing={1}>
                    <img
                      src={contact}
                      style={{
                        height: "30px",
                        width: "auto",
                      }}
                    />
                    <Typography style={{ color: "#fffffe", fontSize: "15px" }}>
                      (+91) 8000 51 60 51
                      <br />
                      Technical Service Contacts
                      <br />
                      (+91) 9408 90 96 82
                      <br />
                      (+91) 8200 49 62 95
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img
                      src={web}
                      style={{
                        height: "30px",
                        width: "auto",
                      }}
                    />
                    <Typography style={{ color: "#fffffe", fontSize: "15px" }}>
                      www.ravitechworld.com
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <img
                      src={Email}
                      style={{
                        height: "30px",
                        width: "auto",
                      }}
                    />
                    <Typography style={{ color: "#fffffe", fontSize: "15px" }}>
                      info@ravitechworld.com
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  // marginLeft="25px"
                  // md={3}
                >
                  <Typography
                    style={{
                      color: "#fffffe",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    ADDRESS
                  </Typography>
                  <Stack direction="row" marginTop="-5px">
                    <img
                      src={address}
                      style={{
                        height: "30px",
                        width: "auto",
                        marginTop: "15px",
                      }}
                    />
                    <p style={{ color: "#fffffe", fontSize: "15px" }}>
                      <Typography
                        style={{
                          color: "#fffffe",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        AHMEDABAD
                      </Typography>
                      <br />
                      A-206, Aarohi Galleria,
                      <br />
                      South Bopal, Ahmedabad
                      <br />
                      Gujarat, India.
                      <br />
                      Pincode: 380-058
                    </p>
                  </Stack>
                  <Stack direction="row" marginTop="-5px">
                    <img
                      src={address}
                      style={{
                        height: "30px",
                        width: "auto",
                        marginTop: "15px",
                      }}
                    />
                    <p style={{ color: "#fffffe", fontSize: "15px" }}>
                      <Typography
                        style={{
                          color: "#fffffe",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        BHAVNAGAR
                      </Typography>
                      <br />
                      301, shobhraj complex,
                      <br />
                      3rd floor sahkari Hat circle,
                      <br />
                      Waghawadi Road, Bhavnagar
                      <br />
                      Gujarat, India.
                      <br />
                      Pincode:364-002
                    </p>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            marginTop="30px"
          >
            <Divider
              sx={{
                backgroundColor: "white",
                width: "73%",
                height: "1px",
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="row" justifyContent="center">
              <Typography
                align="center"
                style={{
                  color: "#fffffe",
                  fontSize: "20px",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                Copyright @ 2021 RavitechWorld.All rights reserved.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default connect(mapStatetoProps, mapSetActiveMenuToProps)(Footer);
