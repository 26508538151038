import Navigation from "./Components/Navigation";
import { HashRouter, Route, Router, Routes } from "react-router-dom";
import Menu from "./Components/Menu";
import { ToastContainer, Zoom } from "react-toastify";
import ScrollToTop from "./Components/ScrollToTop";
import Scroll from "./Components/Scroll";
import { ThemeProvider } from "@mui/material/styles";
import yourTheme from "./Components/yourTheme";
function App() {
  return (
    <HashRouter>
      <ThemeProvider theme={yourTheme}>
        <div className="App">
          <ToastContainer
            position="top-center"
            autoClose={4000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Zoom}
          />
          <Menu />
          <Navigation />
          {/* <ScrollToTop/> */}
        </div>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
